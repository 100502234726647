import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "income"]

  submitForm(e) {
    e.preventDefault()
    e.stopPropagation()

    const field = this.$form.data('field')
    if(field == 'proven_monthly_income')
      this.verifyIncome()

    let fd = new FormData()
    let data = this.$form.serializeArray()

    $.each(data, (key, input) => {
      fd.append(input.name, input.value)
    })

    $.ajax({
      url: this.$form.attr('action'),
      type: 'PUT',
      data: fd,
      dataType: 'json',
      processData: false,
      contentType: false
    })
    .success(data => {
      if($('#proven-indebtedness').length)
        $('#proven-indebtedness').text(data.proven_indebtedness)
    })
  }

  verifyIncome() {
    if(this.$income.val() == "") { return }

    const newValue = parseInt(this.$income.val())
    let customerIncome = this.$form.data('customer-income')
    customerIncome = parseInt(customerIncome)

    if (newValue > customerIncome) {
      const mssg = `El usuario declaró que gana ${customerIncome}, ¿deseas actualizar el monto comprobado a ${newValue}?`
      if (!confirm(mssg))
        this.$income.val(customerIncome)
    }
  }

  get $form() {
    return $(this.formTarget)
  }

  get $income() {
    return $(this.incomeTarget)
  }
}
