var EXIF = require('exif-js');

class RotateImages {
  constructor() {
  }

  fixExifOrientation($img) {
    EXIF.getData($img, function() {
      switch(parseInt(EXIF.getTag(this, "Orientation"))) {
        case 2:
          $($img).addClass('flip'); break;
        case 3:
          $($img).addClass('rotate-180'); break;
        case 4:
          $($img).addClass('flip-and-rotate-180'); break;
        case 5:
          $($img).addClass('flip-and-rotate-270'); break;
        case 6:
          $($img).addClass('rotate-90'); break;
        case 7:
          $($img).addClass('flip-and-rotate-90'); break;
        case 8:
          $($img).addClass('rotate-270'); break;
      }
    });
  }
}
export default RotateImages
