import { Controller } from "stimulus"
import {formatCurrency, formatNumber } from '../helpers/format_currency'

export default class extends Controller {
  static targets=[ "prevDate", "nextDate", "weekDay", "day", "month", "planEndDate", "periods", "gcTooltip", 
    "gcRange", "gcAmountCondonated", "gcPeriodTrigger", "remissionTooltip", "remissionAmountCondonated", "remissionRange",
    "customPeriods", "customAmount", "loan", "planScheduleStartDate", "discountFactor", "postPlanInterest", "lateFeeWaiverAmount",
    "postPlanInterest2", "remissionBase", "balanceAfterCustomInstallments", "noncustomizedInstallmentAmount", "remission",
    "roundingAdjustment", "planTotalPaymentAmount", "planTotalDiscountAmount", "createPlanBtn", "summaryRepaymentCustomAmount",
    "summaryRepaymentAmount"
  ]
  
  isValid = true
  currentErrors = {}

  errorDetails = {
    late_fees_waiver_amount: {
      error: 'El monto debe ser entre 0 y el balance de gastos de cobranza.',
      adjustment_error: 'El monto no puede ser mayor al total de gastos de cobranza.',
      input: 'late_fees_waiver_amount',
      label: 'late_fees_waiver_amount_lbl'
    },
    remission: {
      error: 'El monto debe ser entre 0 y el saldo base.',
      adjustment_error: 'El monto no puede ser mayor al saldo base.',
      input: 'remission',
      label: 'remission_lbl'
    },
    customized_installment_amount: {
      error: 'El monto excede el saldo base restante.',
      adjustment_error: 'El monto no puede ser mayor al saldo base restante.',
      input: 'customized_installment_amount',
      label: 'customized_installment_amount_lbl'
    }
  }

  connect(){
    this.initalDate = new Date(Date.parse(this.prevDateTarget.dataset.initialDate))
    this.animatedLoader = $(document.querySelector('.animation-container'))
    this.setUp()
  }

  setUp(){
    this.loadPlanEndDate(this.initalDate, this.periodsTarget.value)
    this.showRangeTooltip(this.gcRangeTarget, this.gcTooltipTarget)
    this.showRangeTooltip(this.remissionRangeTarget, this.remissionTooltipTarget)
    this.populateTriggerPeriods()
    this.populateCustomPeriods()
  }

  // HANDLERS

  condonationOnInput(e){
    this.showRangeTooltip(e.target, this.gcTooltipTarget)
  }

  condonationOnChange(e){
    const amount = Number(this.gcAmountCondonatedTarget.dataset.lateFeeBalance) * (Number(e.target.value)/100)
    this.gcAmountCondonatedTarget.value = amount.toFixed(2)
    delete this.currentErrors['late_fees_waiver_amount']
    document.getElementById('late_fees_waiver_amount_lbl').style.display = 'none'
    this.gcAmountCondonatedTarget.classList.remove('input-error')
    this.simulate()
  }

  periodsOnChange(e){
    const period = Number(e.target.value)
    if(period < 1){
      e.target.value = 1
    }
    this.loadPlanEndDate(this.prevDateTarget.dataset.currentDate, this.periodsTarget.value)
    this.populateTriggerPeriods()
    this.populateCustomPeriods()
    this.simulate()
  }

  showLoader(show){
    show ? this.animatedLoader.show() : this.animatedLoader.hide()
  }

  gcAmountCondonatedOnKeyup(e){
    formatCurrency($(e.currentTarget), '')
  }

  gcAmountCondonatedOnChange(e){
    let amountToCondonate = 0
    let amountAdjusted = false
    if(e.target.value !== ''){
      amountToCondonate = this.textToNumber(e.target.value)
      if(amountToCondonate < Number(e.target.min)){
        amountAdjusted = true
      }else if(amountToCondonate > Number(e.target.max)){
        amountAdjusted = true
      }
    }else{
      amountToCondonate = Number(e.target.min)
    }
    e.target.value = amountToCondonate.toFixed(2)
    if(amountAdjusted){
      this.showAmountAdjustedMessage('late_fees_waiver_amount')
      this.currentErrors.late_fees_waiver_amount = true
      this.gcAmountCondonatedTarget.classList.add('input-error')
    }else{
      delete this.currentErrors['late_fees_waiver_amount']
      document.getElementById('late_fees_waiver_amount_lbl').style.display = 'none'
      this.gcAmountCondonatedTarget.classList.remove('input-error')
      this.gcRangeTarget.value = amountToCondonate * 100 / Number(this.gcAmountCondonatedTarget.dataset.lateFeeBalance)
      this.showRangeTooltip(this.gcRangeTarget, this.gcTooltipTarget)
      this.simulate()
    }
  }

  remissionOnInput(e){
    this.showRangeTooltip(e.target, this.remissionTooltipTarget)
  }

  remissionAmountOnKeyup(e){
    formatCurrency($(e.currentTarget), '')
  }

  remissionOnChange(e){
    const discount = Number(e.target.value)/100
    const amount = Number(this.remissionAmountCondonatedTarget.dataset.remission) * discount
    this.remissionAmountCondonatedTarget.value = amount.toFixed(2)
    this.discountFactorTarget.value = discount
    delete this.currentErrors['remission']
    document.getElementById('remission_lbl').style.display = 'none'
    this.remissionAmountCondonatedTarget.classList.remove('input-error')
    this.simulate()
  }

  remissionAmountOnChange(e){
    let amountToCondonate = 0
    let amountAdjusted = false
    if(e.target.value !== ''){
      amountToCondonate = this.textToNumber(e.target.value)
      if(amountToCondonate < Number(e.target.min)){
        amountAdjusted = true
      }else if(amountToCondonate > Number(this.remissionAmountCondonatedTarget.dataset.remission)){
        amountAdjusted = true
      }
    }else{
      amountToCondonate = Number(e.target.min)
    }
    e.target.value = amountToCondonate.toFixed(2)
    
    if(amountAdjusted){
      this.showAmountAdjustedMessage('remission')
      this.currentErrors.remission = true
      this.remissionAmountCondonatedTarget.classList.add('input-error')
    }else{
      delete this.currentErrors['remission']
      document.getElementById('remission_lbl').style.display = 'none'
      this.remissionAmountCondonatedTarget.classList.remove('input-error')
      const discountPercentage =  (amountToCondonate / Number(this.remissionAmountCondonatedTarget.dataset.remission)).toFixed(6)
      this.remissionRangeTarget.value =  discountPercentage * 100
      this.discountFactorTarget.value = discountPercentage
      this.showRangeTooltip(this.remissionRangeTarget, this.remissionTooltipTarget, (discountPercentage * 100).toFixed(2))
      this.simulate()
    }
  }

  customPeriodsOnChange(e){
    this.simulate()
  }

  customAmountOnChange(e){
    if(e.target.value === ''){
      e.target.value = 0
    }
    e.target.value = this.textToNumber(e.target.value).toFixed(2)
    this.simulate()
  }

  customAmountOnKeyup(e){
    formatCurrency($(e.currentTarget), '')
  }

  previousDate(e){
    e.preventDefault()
    e.stopImmediatePropagation()
    if(this.prevDateTarget.classList.contains('inactive')) return;

    this.loadNextDueDate(this.prevDateTarget.dataset.currentDate, '/payment_agreements/previous_due_date')
  }

  nextDate(e){
    e.preventDefault()
    e.stopImmediatePropagation()
    this.loadNextDueDate(this.nextDateTarget.dataset.currentDate, '/payment_agreements/next_due_date')
  }
  
  loadNextDueDate(date, url){
    const that = this
    $.ajax({
      url: url,
        type: 'GET',
        contentType: "application/json",
        data:{
          due_date: date
        }
    }).done((data) => {
      that.loadPlanEndDate(data.due_date, that.periodsTarget.value)
      that.showDueDate(data)
      that.simulate()
    }).fail((data) => {
      console.log(data)
    })
  }

  loadPlanEndDate(startDate, periods){
    $.ajax({
      url: '/payment_agreements/plan_end_date',
        type: 'GET',
        contentType: "application/json",
        data: {
          start_date: startDate,
          periods: periods
        }
    }).done((data) => {
      this.showEndDate(data.end_date)
    }).fail((data) => {
      console.log(data)
    })
  }

  showDueDate(data){
    this.weekDayTarget.innerHTML = data.weekday
    this.dayTarget.innerHTML = data.day
    this.monthTarget.innerHTML = data.month

    this.prevDateTarget.dataset.currentDate = data.due_date
    this.nextDateTarget.dataset.currentDate = data.due_date
    this.planScheduleStartDateTarget.value = data.due_date

    if(this.initalDate < Date.parse(data.due_date)){
      this.prevDateTarget.classList.remove('inactive')
    }else{
      this.prevDateTarget.classList.add('inactive')
    }
  }

  showEndDate(endDate){
    this.planEndDateTarget.value = endDate
  }

  showRangeTooltip(target, tooltip, tooltipText){
    const rangeWidth = Number(target.offsetWidth) - 20
    const pixelsToMove = (Number(target.value) * rangeWidth / 100) + 46
    tooltip.textContent = tooltipText ? `${tooltipText}%` : `${target.value}%`
    tooltip.style.left = `${pixelsToMove}px`
  }

  populateTriggerPeriods(){
    const total = Number(this.periodsTarget.value)
    this.gcPeriodTriggerTarget.innerHTML = ''

    if(total == 0){
      let option = document.createElement('option')
      option.value = 0
      option.innerHTML = 0
      this.gcPeriodTriggerTarget.appendChild(option)
    }else{
      for (let i = 1; i <= total; i++){
        let option = document.createElement('option')
        option.value = i
        option.innerHTML = i
        this.gcPeriodTriggerTarget.appendChild(option)
      }
    }
  }

  populateCustomPeriods(){
    const total = Number(this.periodsTarget.value)
    let currentValue = this.customPeriodsTarget.value
    if(currentValue === ''){
      currentValue = '0'
    }
    this.customPeriodsTarget.innerHTML = ''

    if(total == 0){
      let option = document.createElement('option')
      option.value = 0
      option.innerHTML = 0
      this.customPeriodsTarget.appendChild(option)
    }else{
      for (let i = 0; i <= total-1; i++){
        let option = document.createElement('option')
        option.value = i
        option.innerHTML = i
        this.customPeriodsTarget.appendChild(option)
      }
    }
    this.customPeriodsTarget.value = currentValue
  }

  textToNumber(amount){
    return Number(amount.replace(/[\$,]/g, ''))
  }
  
  simulate(){
    if(!this.validForm()) { return };
    this.showLoader(true)
    $.ajax({
      url: '/payment_agreements/simulate',
        type: 'POST',
        contentType: "application/json",
        data: JSON.stringify({
          loan_id: this.loanTarget.value,
          plan_schedule_start_date: this.planScheduleStartDateTarget.value,
          selected_number_of_equal_installments: this.periodsTarget.value,
          late_fees_waiver_amount: this.textToNumber(this.gcAmountCondonatedTarget.value),
          first_payment_to_credit_entry_ratio: this.discountFactorTarget.value,
          number_of_customized_installments: this.customPeriodsTarget.value,
          customized_installment_amount: this.textToNumber(this.customAmountTarget.value)
        })
    }).done((data) => {
      this.showLoader(false)
      if(Object.entries(data.errors).length === 0){
        this.refreshPlan(data)
        this.hideErrors()
        this.isValid = true
      }else{
        this.isValid = false
        this.showErrors(data.errors)
      }
    }).fail((data) => {
      this.showLoader(false)
      console.log(data)
    })
  }

  refreshPlan(data){
    this.postPlanInterestTarget.innerHTML = data.post_plan_interest
    this.postPlanInterest2Targets.forEach(e => {
      e.innerHTML = `(${data.post_plan_interest})`
    });
    this.lateFeeWaiverAmountTargets.forEach(e => {
      e.innerHTML = `(${data.late_fees_waiver_amount_formatted})`
    });
    this.remissionBaseTarget.innerHTML = data.remission_base_formatted
    this.balanceAfterCustomInstallmentsTarget.innerHTML = data.plan_balance_after_customized_installments
    this.noncustomizedInstallmentAmountTarget.innerHTML = data.plan_repayment_amount
    this.remissionTarget.innerHTML = `(${data.remission_formatted})`
    this.roundingAdjustmentTarget.innerHTML = `(${data.rounding_adjustment})`
    this.planTotalPaymentAmountTarget.innerHTML = data.plan_total_payment_amount
    this.planTotalDiscountAmountTarget.innerHTML = data.plan_total_discount_amount
    this.remissionAmountCondonatedTarget.value = data.remission
    this.remissionAmountCondonatedTarget.dataset.remission = data.remission_base
    formatCurrency($(this.gcAmountCondonatedTarget), '')
    formatCurrency($(this.remissionAmountCondonatedTarget), '')
    formatCurrency($(this.customAmountTarget), '')
    
    if(this.customPeriodsTarget.value == 0){
      this.summaryRepaymentCustomAmountTarget.innerHTML = '-'
    }else{
      this.summaryRepaymentCustomAmountTarget.innerHTML = `${this.customPeriodsTarget.value} de ${this.customAmountTarget.value}`
    }
    const totalStandarPeriods = Number(this.periodsTarget.value) - Number(this.customPeriodsTarget.value)
    this.summaryRepaymentAmountTarget.innerHTML = `${totalStandarPeriods} de ${data.plan_repayment_amount}`
    
  }

  createPlanOnClick(e){
    if(this.isValid && this.validForm()){
      this.showLoader(true)
      e.target.disabled = true
      this.createPlan()
    }
  }

  createPlan(){
    $.ajax({
      url: '/payment_agreements',
        type: 'POST',
        contentType: "application/json",
        data: JSON.stringify({
          loan_id: this.loanTarget.value,
          plan_schedule_start_date: this.planScheduleStartDateTarget.value,
          selected_number_of_equal_installments: this.periodsTarget.value,
          late_fees_waiver_amount: this.textToNumber(this.gcAmountCondonatedTarget.value),
          waiver_trigger_installment: this.gcPeriodTriggerTarget.value,
          first_payment_to_credit_entry_ratio: this.discountFactorTarget.value,
          number_of_customized_installments: this.customPeriodsTarget.value,
          customized_installment_amount: this.textToNumber(this.customAmountTarget.value)
        })
    }).done((data) => {
      localStorage.setItem('paymentAgreementCreated', 1)
      window.location.href = `/payment_agreements/${data.id}`
    }).fail((data) => {
      this.showLoader(false)
      this.createPlanBtnTarget.disabled = false
      console.log(data)
    })
  }

  showErrors(errors){
    for (const error of Object.entries(errors)) {
      const [fieldName, fieldError] = error
      console.log(`${fieldName}: ${fieldError}`)
      const errorDetail = this.errorDetails[fieldName.toString()]
      if(errorDetail !== undefined){
        document.getElementById(errorDetail.input).classList.add('input-error')
        const lblElement = document.getElementById(errorDetail.label)
        lblElement.style.display = 'block'
        lblElement.textContent = errorDetail.error
      }
    }
  }

  hideErrors(){
    document.querySelectorAll('.payment-agreement-box .lbl-error').forEach((e) =>{
      e.style.display = 'none'
    })

    document.querySelectorAll('.payment-agreement-box .input-error').forEach((e) =>{
      e.classList.remove('input-error')
    })
  }

  showAmountAdjustedMessage(field){
    const errorDetail = this.errorDetails[field]
    if(errorDetail !== undefined){
      const lblElement = document.getElementById(errorDetail.label)
      lblElement.style.display = 'block'
      lblElement.textContent = errorDetail.adjustment_error
    }
  }

  validForm(){
    return Object.entries(this.currentErrors).length === 0
  }
}
