import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "elementId"]

  displayDetail(e) {
    e.preventDefault()
    let id = e.currentTarget.getAttribute('data-target-id')
    let icon = document.getElementById('icon-' + id)

    document.getElementById(id).classList.toggle('credit-rating-detail')

    if(icon.className == 'fa fa-plus') {
      icon.className = 'fa fa-minus'
    }else {
      icon.className = 'fa fa-plus'
    }
  }
}
