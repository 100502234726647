import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.fieldsList = [['Select...', ''], ['Id préstamo', 'id'], ['Id cliente', 'customer_id'], 
      ['Días de mora', 'overdue_days'],  ['Periodos de mora', 'overdue_periods'], 
      ['Id Link', 'agent_id'],['Saldo Exigible', 'total_balance'],['Tipo préstamo', 'operation_type']]
  }

  appendFields(e) {
    e.preventDefault();
    e.stopPropagation();

    let cln = document.getElementsByClassName("fields")[0].cloneNode(true);
    cln.querySelectorAll("select,input").forEach((i) => { i.value = ''})
    
    let filterSelect = cln.querySelector("div.fields select.filterField")
    this.updateSelectOptions(filterSelect)

    cln.getElementsByTagName("button")[1].classList.remove('d-none')
    cln.getElementsByTagName("button")[0].classList.add('d-none')
    document.getElementById("input-list").appendChild(cln);
  }

  availableIds() {
    let ids_selected = this.idsSelected()
    return this.fieldsId().filter(id => !ids_selected.includes(id));
  }

  idsSelected() {
    let ids_selected = []
    let checkedOptions = document.querySelectorAll("div.fields select.filterField option:checked")
    ids_selected = [...checkedOptions].map((opt) => opt.value)
    return ids_selected
  }

  fieldsId(){
    return this.fieldsList.map((e) => e[1])
  }

  updateFiledsList(e) {
    let currentOption = e.target.options[e.target.options.selectedIndex].value
    this.resetSelects()
    let selectedOption = this.createOption(currentOption)
    selectedOption.selected = true;
    e.target.add(selectedOption)
  }

  createOption(id) {
    let option = document.createElement("option");
    option.text = this.fieldsList.find(e => e[1] === id)[0];
    option.value = id;
    return option
  }

  updateSelectOptions(selectElement, selectedId = null) {
    selectElement.innerHTML = ''
    this.availableIds().forEach( id => {
      let option = this.createOption(id)
      option.selected = id == selectedId;
      selectElement.add(option); 
    })
  }

  deleteRow(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.parentNode.remove();
    
    this.resetSelects()
  }

  resetSelects() {
    document.querySelectorAll("div.fields select.filterField").forEach(ele => {
      let selectedValue = ele.querySelector("option:checked").value
      this.updateSelectOptions(ele, selectedValue)
    })
  }

}
