import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    
  }

  open(){
    $.ajax({
      url: '/amortization/overdue_reminders',
      type: 'GET',
    }).done((data) => {
      let tbody = $("#whatsapp-reminders-body")
      let index = 1
      tbody.empty()
      data.messages.forEach(msg => {
        const url = `https://web.whatsapp.com/send?phone=${msg.mobile_phone}&text=${msg.message}`
        let row = document.getElementById("whatsapp-link-row").content.cloneNode(true)
        var link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('target','_blank');
        link.innerHTML = 'Enviar whatsapp';
        $('.c1',row).html(index)
        $('.c2',row).html(msg.mobile_phone)
        $('.c3',row).append(link)
        tbody.append(row)
        index++
      });
      $('#whatsapps-reminders-modal').modal('show')
    }).fail((data) => {
      console.log(data)
    })
  }
}
