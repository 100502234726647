export function formatCurrency(input, blur, range) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.
  // get input value
  let input_val = input.val();
  
  // don't validate empty input
  if (input_val === "") { return; }
  
  // original length
  let original_len = input_val.length;

  // initial caret position 
  let caret_pos = input.prop("selectionStart");

  if(range){
    let unformattedNumber = Number(input_val.replace(/[\$,]/g, ''))
    if(range.max && unformattedNumber > range.max){
      input_val = range.max
    }else if(range.min && unformattedNumber < range.min){
      input_val = range.min
    }
  }

  // check for decimal
  if (input_val.indexOf(".") >= 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    let decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    let left_side = input_val.substring(0, decimal_pos);
    let right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);
    
    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      right_side += "00";
    }
    
    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    input_val = "$" + left_side + "." + right_side;  
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = "$" + input_val

    // final formatting
    if (blur === "blur") {
      input_val += ".00";
    }
  }

  // send updated string to input
  input.val(input_val);

  // put caret back in the right position
  let updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0].setSelectionRange(caret_pos, caret_pos);
}

export function formatNumber(n) {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}