import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["progressContainer", "progressBar", "progressAmount",
                    "submitButton", "directDebitId", "responseId", "fileInput"]

  initialize() {
    this.submitButtonTarget.disabled = true
  }

  dragOverHandler(e) {
    console.log("File detected!!")
    e.preventDefault()
    e.stopPropagation()
  }

  dropHandler(e) {
    if(e.dataTransfer.items.length > 1) {
      this.handleMultipleFiles(e)
      return false
    } else {
      e.preventDefault()
      e.stopPropagation()
      let file = e.dataTransfer.files[0]
      if (file) {
        this.sendFile(file)
        this.removeDragData(e)
      }
    }
  }

  manualUploadFile(e) {
    e.preventDefault()
    e.stopPropagation()
    this.$fileInput.click()
  }

  handleFile(e) {
    e.preventDefault()
    e.stopPropagation()
    let file = e.currentTarget.files[0]
    if(file) {
      this.sendFile(file)
    }
  }

  handleMultipleFiles(e) {
    console.log('muchos archivos')
    e.preventDefault()
    e.stopPropagation()
  }

  removeDragData(e) {
    console.log('Removing drag data')

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      e.dataTransfer.items.clear()
    } else {
      // Use DataTransfer interface to remove the drag data
      e.dataTransfer.clearData()
    }
  }

  pageReload(e) {
    location.reload()
  }

  deleteResponse(e) {
    let directDebitId = this.$directDebitId.value
    let responseId = this.responseIdTarget.value
    let form = new FormData()
    if(responseId == ""){
      $("#fileUploadModal").modal("hide")
    } else {
      form.set('response_id', responseId)
      $.ajax({
        url: `/direct_debits/${directDebitId}/destroy_response`,
        type: 'POST',
        data: form,
        dataType: 'json',
        processData: false,
        contentType: false
      }).done( data => {
        console.log(data)
        if(data.status == "success"){
          $("#fileUploadModal").modal("hide")
        }
      }).fail((jqXHR, textStatus) => {
        console.log("failed")
      }).always((dataOrjqXHR, textStatus, jqXHRorErrorThrown) =>{
        console.log("always")
      })
    }
  }

  sendFile(file) {
    let uri = '/direct_debits/reconcile'
    let form = new FormData()
    this.$progressContainer.classList.add("show")
    let progressBar = this.$progressBar
    let progressAmount = this.$progressAmount
    let progress = 1
    let directDebitId = this.$directDebitId.value
    console.log(directDebitId)

    // TODO remove the line below
    //form.set('business_document[business_document_type]', 'BbvaDdl')
    form.set('direct_debit[uri]', file)
    form.set('direct_debit[id]', directDebitId)

    $.ajax({
      url: uri,
      type: 'POST',
      data: form,
      dataType: 'json',
      processData: false,
      contentType: false,
      xhr: function() {
        var xhr = new window.XMLHttpRequest();

        // Upload progress
        xhr.upload.addEventListener("progress", function(evt){
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            percentComplete = parseInt(percentComplete * 100);
            progressAmount.innerHTML = percentComplete
          }
        }, false);

        return xhr;
      },
    }).done( data => {
      console.log(data)
      var direct_debit_responses = data.direct_debit.direct_debit_responses
      var response = direct_debit_responses.pop()
      this.responseIdTarget.value = response.id
      progressBar.style['width'] = `100%`
      this.submitButtonTarget.disabled = false
    }).fail((jqXHR, textStatus) => {
      console.log("failed")
    }).always((dataOrjqXHR, textStatus, jqXHRorErrorThrown) =>{
      console.log("always")
    })
  }

  get $progressContainer() {
    return this.progressContainerTarget
  }

  get $progressBar() {
    return this.progressBarTarget
  }

  get $progressAmount() {
    return this.progressAmountTarget
  }

  get $directDebitId() {
    return this.directDebitIdTarget
  }

  get $fileInput() {
    return this.fileInputTarget
  }
}
