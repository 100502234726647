import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    console.log('header search filters connected')
    if(window.location.search){
      this.populateFilters()
    }else{
      localStorage.removeItem(this.getStorageKey())
    }
    
    $('.header-filter .dropdown-menu').click(e => {
      e.stopPropagation();
    })
  }

  populateFilters(){
    const filterState = this.getFiltersState()
    filterState.filters.forEach( e => {
      const container = document.querySelector(`div[data-filter-field="${e.fieldName}"]`)
      
      if(typeof e.valueSelector === 'object'){
        e.valueSelector.forEach( s => {
          container.querySelector(s.valueSelector).checked = true
        })
      }else{
        container.querySelector(e.valueSelector).value = e.filterValue  
      }
      if(e.operatorSelector !== ''){
        container.querySelector(e.operatorSelector).value = e.operator
      }
    })
    filterState.sorts.forEach( e => {
      const container = document.querySelector(`div[data-filter-field="${e.fieldName}"]`)
      const sortField = container.querySelector(`.ddc-sorting-group a[data-sort="${e.sortType}"]`)
      sortField.classList.remove('notActive')
      sortField.classList.add('active')

      const arrow = container.parentElement.querySelector('.arrow-down')
      arrow.classList.add(`sort-${e.sortType}`)
    })
  }
  
  getFiltersState(){
    let state = JSON.parse(localStorage.getItem(this.getStorageKey()))
    if(state === null){
      state = {
        filters: [],
        sorts: []
      }
    }
    return state
  }

  sortSelected(e){
    parent = e.currentTarget.parentElement
    $('a.active', parent).removeClass('active').addClass('notActive')
    $(e.currentTarget).removeClass('notActive').addClass('active')
  }

  filter(e){
    const filterIndex = e.currentTarget.dataset.index
    const container =  document.getElementById(`ddcFilter${filterIndex}`)
    const field = container.dataset.filterField
    const filterType = container.dataset.filterType

    let sortType = container.querySelector('.ddc-sorting-group a.active')
    sortType = sortType ? sortType.dataset.sort : ''
    const sort = {}
    if(sortType !== ''){
      sort.fieldName = field
      sort.sortType = sortType
    }
    
    let filters = []
    let filter = {}
    if(filterType === 'checklist'){
      let valueSelectorOpts = []
      let filterValues = []
      container.querySelectorAll('.checklist-container input[type="checkbox"]:checked').forEach( (e) => {
        valueSelectorOpts.push({
          valueSelector: `#${e.id}`
        })
        filterValues.push(e.value)
      })
      if(valueSelectorOpts.length > 0){
        filter = {}
        filter.fieldName = field
        filter.filterValue = filterValues.join(',')
        filter.operator = 'in'
        filter.operatorSelector = ''
        filter.valueSelector = valueSelectorOpts
        filters.push(filter)
      }
    }else{
      let filterValue = container.querySelector(`#ddcFilterField${filterIndex}`).value
      let operator = container.querySelector(`#ddc-operator${filterIndex}`).value
      if(filterValue !== ''){
        filter.fieldName = field
        filter.filterValue = filterValue
        filter.operator = operator
        filter.operatorSelector = `#ddc-operator${filterIndex}`
        filter.valueSelector = `#ddcFilterField${filterIndex}`
        filters.push(filter)
      }
      if(filterType === 'range'){
        const extraFilter = container.querySelector(`#ddcFilterField2${filterIndex}`)
        filterValue = extraFilter.value
        operator = container.querySelector(`#ddc-operator2${filterIndex}`).value
        filter = {}
        if(filterValue !== ''){
          filter.fieldName = field
          filter.filterValue = filterValue
          filter.operator = operator
          filter.operatorSelector = `#ddc-operator2${filterIndex}`
          filter.valueSelector = `#ddcFilterField2${filterIndex}`
          filters.push(filter)
        }
      }
    }
    this.storeFilterState(field,filters, sort)
    e.currentTarget.dispatchEvent(new CustomEvent('header-filter-triggered', { bubbles: true }))
  } 

  clearFilter(e){
    const filterIndex = e.currentTarget.dataset.index
    const container = document.getElementById(`ddcFilter${filterIndex}`)
    const field = container.dataset.filterField
    const filterState = this.getFiltersState()
    
    filterState.filters = filterState.filters.filter( f => { 
      return f.fieldName !== field
    })
    filterState.sorts = filterState.sorts.filter( s => { 
      return s.fieldName !== field
    })
    localStorage.setItem(this.getStorageKey(), JSON.stringify(filterState))
    e.currentTarget.dispatchEvent(new CustomEvent('header-filter-triggered', { bubbles: true }))
  }

  storeFilterState(fieldName, filters, sort){
    let filterState = this.getFiltersState()
    if(filters.length){
      const f = filterState.filters.filter( filter => {
        return filter.fieldName !== fieldName
      })
      f.push(...filters)
      filterState.filters = f
    }
    if(Object.keys(sort).length !== 0){
      const sorts = filterState.sorts.filter( sort => {
        return sort.fieldName !== fieldName
      })
      sorts.push(sort)
      filterState.sorts = sorts
    }
    localStorage.setItem(this.getStorageKey(), JSON.stringify(filterState))
  }

  getStorageKey(){
    return 'ddc-filters-state'
  }
}
