import 'react-dates/initialize';
import React from "react"
import PropTypes from "prop-types"
import { DateRangePicker } from 'react-dates';
import moment from "moment-timezone";
import 'react-dates/lib/css/_datepicker.css';
import './range_datepicker.css'

class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.setup()
    this.state ={
      startDate: localStorage.getItem('accounting-startDate') === null ? null : moment(localStorage.getItem('accounting-startDate'),'YYYY-MM-DD'),
      endDate: localStorage.getItem('accounting-startDate') === null ? null : moment(localStorage.getItem('accounting-endDate'),'YYYY-MM-DD'),
      focusedInput: null
    }
  }

  setup(){
    moment.locale('es');
    const urlParams = new URLSearchParams(window.location.search);
    const from_date = urlParams.get('from_date');
    if(from_date === null || from_date === ''){
      localStorage.removeItem('accounting-startDate')
    }

    const to_date = urlParams.get('to_date');
    if(to_date === null || to_date === ''){
      localStorage.removeItem('accounting-endDate')
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    if(startDate !== null){
      localStorage.setItem('accounting-startDate', startDate.format('YYYY-MM-DD'))
    }
    if(endDate !== null){
      localStorage.setItem('accounting-endDate', endDate.format('YYYY-MM-DD'))
    }
    this.setState({ startDate, endDate });
  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  }

  render () {
    return (
      <React.Fragment>
        <DateRangePicker
          startDate={this.state.startDate}
          startDateId="from_date"
          endDate={this.state.endDate}
          endDateId="to_date"
          onDatesChange={this.onDatesChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.onFocusChange}
          isOutsideRange={() => false}
          startDatePlaceholderText= 'Inicio'
          endDatePlaceholderText='Fin'
          displayFormat={() => "YYYY/MM/DD"}
        />
      </React.Fragment>
    );
  }
}

export default RangeDatePicker
