export function ajaxRequestAlert(ajaxFunction, type, title, text, confirmText) {
  return Swal.fire({
    title: title,
    text: text,
    type: type,
    showCancelButton: true,
    confirmButtonText: confirmText,
    cancelButtonText: 'No, cancelar',
    reverseButtons: true,
    showLoaderOnConfirm: true,
    allowOutsideClick: false,
    preConfirm: ajaxFunction
  });
}

export function simpleAlert(title, text, type) {
  Swal.fire(title, text, type)
}
