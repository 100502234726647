import { Controller } from "stimulus"

export default class extends Controller {
  // Interact With Direct Debits Form 
  initialize() {
    this.confMessage = document.querySelector('#conf-message')
    this.factorsShow = false
    this.omissionsShow = false
    this.amountsShow = false
  }

  collapseConfigMessage(e) {
    let target = e.currentTarget.dataset.target
    this.updateShowingSection(target)
    if (this.factorsShow || this.omissionsShow || this.amountsShow) {
      this.confMessage.classList.remove("show")
    }else {
      this.confMessage.classList.add("show")
    }
  }

  updateShowingSection(element) {
    switch(element) {
      case '.collapse-factors':
        this.factorsShow = !this.factorsShow
        break
      case '.collapse-omissions':
        this.omissionsShow = !this.omissionsShow
        break
      case '.collapse-amounts':
        this.amountsShow = !this.amountsShow
        break
    }
  }
}
