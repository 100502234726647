import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['logoFile' , 'contractFile', 'postalCode', 'neighborhood']
  
  connect(){
    this.logoFileTarget.onchange = (e) => {
      const input = e.currentTarget
      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = function(event) {
          document.getElementById('partnersip_logo').src = event.target.result
        }
        reader.readAsDataURL(input.files[0]);
      }
    }

    if(this.postalCodeTarget.value !== ''){
      setTimeout(function(){
        let e = document.createEvent('HTMLEvents');
        e.initEvent('keyup', false, true);
        this.postalCodeTarget.dispatchEvent(e);
        setTimeout(function(){
          this.neighborhoodTarget.value = this.neighborhoodTarget.dataset.value
        }.bind(this), 1000)
      }.bind(this), 500)
    }
  }

  fileModal(e){
    this.logoFileTarget.click()
  }

  contractModal(e){
    this.contractFileTarget.click()
  }
}