import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "btn" ]
  
  connect(){
    $(this.btnTarget).on('click', (e) => {
      this.load(`/csv_reports?report_type=${this.reportType()}`)
    })

    const paginator = function(e){
      e.preventDefault()
      this.load(e.currentTarget.href)
    }

    $(document).on('click', '.csv-report-pagination a', paginator.bind(this) )
  }

  load(url){
    $.ajax({
      url: url,
      type: 'GET',
    }).done((data) => {
      document.getElementById('csvreports-body').innerHTML = data.html
      $('#csvReports').modal('show')
    }).fail((data) => {
      console.log(data)
    })
  }

  reportType(){
    return this.btnTarget.dataset.reportType
  }
}
