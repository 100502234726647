import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    document.addEventListener('header-filter-triggered', e => {
      this.loadAgreements()
    });
  }

  loadAgreements(){
    window.location.href = this.stateToParams('/payment_agreements')
  }

  stateToParams(baseUrl){
    const filterState = this.getFiltersState()
    let filter_params = []
    filterState.filters.forEach( e => {
      filter_params.push(`filter_args[]=${e.fieldName}`)
      filter_params.push(`filter_args[]=${e.operator}`)
      filter_params.push(`filter_args[]=${e.filterValue}`)
    })

    let sort_params = []
    filterState.sorts.forEach( e => {
      sort_params.push(`sort_args[]=${e.fieldName}`)
      sort_params.push(`sort_args[]=${e.sortType}`)
    })
    return `${baseUrl}?${filter_params.join('&')}${ sort_params.length ? `&${sort_params.join('&')}}` : ''}`
  }

  getFiltersState(){
    let state = JSON.parse(localStorage.getItem(this.getStorageKey()))
    if(state === null){
      state = {
        filters: [],
        sorts: []
      }
    }
    return state
  }

  getStorageKey(){
    return 'ddc-filters-state'
  }
}