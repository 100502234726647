import { Controller } from "stimulus";
import { ajaxRequestAlert, simpleAlert } from '../helpers/sweet_alert_implementation';

export default class extends Controller {
  connect() {}

  discardAlert(e) {
    const title = '¿Estás seguro?';
    const text = 'Descartarás la alerta, no hay forma de revertir esto';
    const confirmText = 'Sí, descartar';
    const target = e.currentTarget;
    const url = $(target).data('url');
    const ajaxFunction = this.ajaxFunction(url);

    ajaxRequestAlert(ajaxFunction, 'warning', title, text, confirmText).then((result) => {
      if(result.value) {
        if (result.value == 'ok') {
          this.updateView(parseInt($('#alert-size').html()), target);
          simpleAlert('¡Información actualizada!', '', 'success');
        } else
          simpleAlert('Error', '', 'error');
      } else if(result.dismiss === Swal.DismissReason.cancel)
        simpleAlert('Acción cancelada', '', 'error');
    });
  }

  ajaxFunction(url) {
    const ajaxFunction = () => {
      return $.ajax({
        url: url,
        type: 'DELETE',
        dataType: 'json',
        processData: false,
        contentType: false
      })
      .success(response => {
        return response;
      });
    }

    return ajaxFunction;
  }

  updateView(alertSize, target) {
    alertSize -= 1;
    $('#alert-size').html(alertSize);
    $(target).closest('tr').remove();

    if(alertSize == 0) {
      $('#content-box').addClass('d-none');
      $('#empty-box').removeClass('d-none');
    }
  }
}
