import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "zipcode", "colony", "city", "state" ]

  connect() {
    this.setupInitialZipcode()

    $(document).on('resetZipcode', this.setupInitialZipcode.bind(this))
    $(document).on('fillZipcode', this.zipCodeChange.bind(this))
  }

  setupInitialZipcode() {
    let zipcode = this.$zipcode.data('current-zipcode')
    let colony = this.$zipcode.data('current-colony')

    if (zipcode && colony) {
      this.$zipcode.val(zipcode)
      this.zipCodeChange().then((data) => {
        this.$colony.val(colony)
      })
    }
  }

  zipCodeChange(e) {
    let value = this.zipcodeValue

    if (value.length === 5) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/zip_codes.json?postal_code='+value,
          type: 'GET',
        }).done((data) => {
          this.setupZipcodeInfo(data)
          resolve(data)
        }).fail((data) => {
          reject(data)
        })
      })
    }
  }

  setupZipcodeInfo(data) {
    if (data === undefined || data.length == 0) {
      return
    }

    // hidden fields
    let state = data[0].d_estado
    let city = data[0].d_mnpio
    this.$state.val(state)
    this.$city.val(city)

    // colonies dropdown
    this.$colony.empty().append($('<option>', {value: '', text: 'Por favor seleccione'}))
    $.each(data, (index, item) => {
      this.$colony.append($('<option>', {value: item.d_asenta, text: item.d_asenta}))
    })
  }

  get zipcodeValue() {
    return this.zipcodeTarget.value
  }

  get $zipcode() {
    return $(this.zipcodeTarget)
  }

  get $colony() {
    return $(this.colonyTarget)
  }

  get $city() {
    return $(this.cityTarget)
  }

  get $state() {
    return $(this.stateTarget)
  }
}

