import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    this.setUpHeaderTitleObserver()

    document.addEventListener('header-filter-triggered', e => {
      this.loanDashboard()
    });
  }

  setUpHeaderTitleObserver(){
    let ddc_observer_handler = entries => {
      let previousY = 0
      let trSticktable1 = document.getElementById('tr-stickable1')
      let trSticktable2 = document.getElementById('tr-stickable2')
      let ddcContainer = document.getElementById('ddc-container')
      entries.forEach(entry => {
        const currentY = entry.boundingClientRect.y
        const isIntersecting = entry.isIntersecting
        
        if (currentY < previousY && !isIntersecting){
          trSticktable1.style.display = 'table-row'
          trSticktable2.style.display = 'table-row'
          ddcContainer.style.overflowX = 'visible'
        } else {
          trSticktable1.style.display = 'none'
          trSticktable2.style.display = 'none'
          ddcContainer.style.overflowX = 'auto'
        }
        previousY = currentY
      })
    }
    const ddc_observer_config = {
      threshold: [0,1]
    }
    
    const ddc_observer = new IntersectionObserver(ddc_observer_handler, ddc_observer_config);
    ddc_observer.observe(document.getElementById('ddc-sentinel'));
  }

  export(e){
    e.preventDefault()
    e.stopPropagation()
    window.location.href = this.stateToParams('/direct_debits/amortizations_balances_to_csv')
  }

  loanDashboard(){
    window.location.href = this.stateToParams('/direct_debits/amortizations_balances')
  }

  stateToParams(baseUrl){
    const filterState = this.getFiltersState()
    let filter_params = []
    filterState.filters.forEach( e => {
      filter_params.push(`filter_args[]=${e.fieldName}`)
      filter_params.push(`filter_args[]=${e.operator}`)
      filter_params.push(`filter_args[]=${e.filterValue}`)
    })

    let sort_params = []
    filterState.sorts.forEach( e => {
      sort_params.push(`sort_args[]=${e.fieldName}`)
      sort_params.push(`sort_args[]=${e.sortType}`)
    })
    const renewals = document.getElementById('renewals').checked ? true : false
    const chargedOff = document.getElementById('charged_off').checked ? true : false
    const due_date = document.querySelector('select[name="due_date"]').value
    let queryString = `?due_date=${due_date}&renewals=${renewals}&charged_off=${chargedOff}&${filter_params.join('&')}&${sort_params.join('&')}`
    return queryString === '?due_date=&&' ? baseUrl : `${baseUrl}${queryString}`
  }

  getFiltersState(){
    let state = JSON.parse(localStorage.getItem(this.getStorageKey()))
    if(state === null){
      state = {
        filters: [],
        sorts: []
      }
    }
    return state
  }

  getStorageKey(){
    return 'ddc-filters-state'
  }
}