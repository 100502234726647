import { Controller } from "stimulus"
import { ifError, throws } from "assert"

export default class extends Controller {
  static targets = [ "q" , "icons", "arrowDown", "btnAddLoan", "documentId" ]

  connect() {
    $('.loanlist-container').on('click', 'li', (e) => {
      this.addToLoanSelected(e)
    })

    $('.loan-preselected-box, #loan-selected-box').on('click','.fa',(e) => {
      this.removeSelectedLoan(e)
      this.hideAddLoanButton(e)
    })

    const urlParams = new URLSearchParams(window.location.search);
    const show_in = urlParams.get('show_in');
    if(show_in !== null && show_in !== ''){
      document.getElementsByName('show_in').forEach(element => {
        if(element.value === show_in){
          element.checked = true
        }else{
          element.checked = false
        }
      })
    }else{
      document.querySelector('input[name="show_in"][value="thousands"]').checked = true
    }


    const account_type = urlParams.getAll('account_type[]')
    document.getElementsByName('account_type[]').forEach(element => {
      if(account_type.indexOf(element.value) >= 0){
        element.checked = true
      }else{
        element.checked = false
      }
    })

    const loans = urlParams.get('grouping_document_id')
    if(loans !== null && loans !== ''){
      document.getElementById('loan-selected-box').innerHTML = localStorage.getItem('searched-loans')
      this.documentIdTarget.value = loans
    }else{
      localStorage.removeItem('searched-loans')
    }
  }
  
  load(e){
    const query = encodeURIComponent(e.target.value)
    if( query !== '' ){
      $.ajax({
        url: '/loans',
          type: 'GET',
          contentType: "application/json",
          data:{
            q: encodeURIComponent(e.target.value),
            format:'json'
          }
        }).done((data) => {
          let searchContainer = $('.loanlist-container ul')
          searchContainer.empty()
          
          let preselectedLoans = this.preSelectedItemsIds()

          data.forEach(element => {
            searchContainer.append(`<li data-id=${element.id}><input type="checkbox" ${ preselectedLoans.indexOf(element.id) !== -1 ? 'checked' : '' }><span class="ml-2">${element.id} - ${element.customer_name}</span></li>`)
          })
        }).fail((data) => {
          console.log(data)
      })
    }else{
      this.clear(false)
    }
  }

  showlist(){
    let loanList = document.getElementById('loan_list_container')
    if(!loanList.classList.contains('show')){
      this.qTarget.placeholder = ''
      this.iconsTargets.forEach( e => e.style.visibility = 'visible' )
      this.arrowDownTarget.style.visibility = 'hidden'
      this.moveSelectToPreselected()
      loanList.classList.toggle('show')
      if(document.querySelectorAll('.loan-preselected-box > div').length > 0){
        if(this.btnAddLoanTarget.classList.contains('inactive')){
          this.btnAddLoanTarget.classList.remove("inactive")
        }
      }
    }
  }

  moveSelectToPreselected(){
    let preSelecteContainer = document.querySelector('.loan-preselected-box')
    document.querySelectorAll('#loan-selected-box > div').forEach(e => {
      preSelecteContainer.appendChild(e)
    })
  }

  q_blur(){
    this.qTarget.placeholder = 'Buscar ID de préstamos o nombre de cliente.'
    this.arrowDownTarget.style.visibility = 'visible'
    this.iconsTargets.forEach( e => e.style.visibility = 'hidden' )
  }

  clear(removePreSelected){
    if(removePreSelected){
      document.querySelectorAll('.loan-preselected-box > div').forEach(e => {
        e.parentNode.removeChild(e)
      })
    }
    document.querySelectorAll('#loan_list_container > ul  > li').forEach(e => {
      e.parentNode.removeChild(e)
    })
    this.qTarget.value = ''
  }

  addToLoanSelected(e){
    let liElement = e.currentTarget
    let checkBox = liElement.querySelector('input')
    if(checkBox.checked){
      checkBox.checked = false
      let selectedLoan = document.querySelector(`.loan-preselected-box .loan-selected[data-id="${liElement.getAttribute('data-id')}"]`)
      selectedLoan.parentNode.removeChild(selectedLoan);
      this.hideAddLoanButton()
    }else{
      checkBox.checked = true
      let container = document.querySelector('.loan-preselected-box')
      const item = $(`<div class="loan-selected mt-2" data-id="${liElement.getAttribute('data-id')}">${liElement.querySelector('span').textContent } <i class="fa fa-times"></i></div>`)
      container.appendChild(item[0])
      if(this.btnAddLoanTarget.classList.contains('inactive')){
        this.btnAddLoanTarget.classList.remove("inactive")
      }
    }
  }

  removeSelectedLoan(e){
    if(document.getElementById('loan_list_container').classList.contains('show')){
      let liElement = document.querySelector(`#loan_list_container > ul > li[data-id="${e.currentTarget.parentNode.getAttribute('data-id')}"]`)
      if(liElement !== null){
        liElement.querySelector('input').checked = false
      }
      const divContainer = e.currentTarget.parentNode
      divContainer.parentNode.removeChild(divContainer)
      this.saveLoansLocally(document.querySelectorAll('.loan-preselected-box > div'))
    }else{
      e.currentTarget.parentNode.parentNode.removeChild(e.currentTarget.parentNode)
      this.saveLoansLocally(document.querySelectorAll('#loan-selected-box > div'))
    }
  }

  hideAddLoanButton(e){
    if(document.getElementById('loan_list_container').classList.contains('show')){
      if(document.querySelectorAll('.loan-preselected-box > div').length === 0 ) {
        this.btnAddLoanTarget.classList.add("inactive")
      } 
    }else{
      if( document.querySelectorAll('#loan-selected-box > div').length === 0 ) {
        this.btnAddLoanTarget.classList.add("inactive")
      } 
    }
  }

  saveLoansLocally(nodes){
    let childsAsString = ''
    nodes.forEach(e =>{
      childsAsString += e.outerHTML
    })
    localStorage.setItem('searched-loans',childsAsString)
  }

  addLoans(e){
    if(!e.currentTarget.classList.contains('inactive')){
      let loansContainer = document.getElementById('loan-selected-box')
      if(!this.btnAddLoanTarget.classList.contains('inactive')){
        let ids = []
        let nodes = document.querySelectorAll('.loan-preselected-box > div')
        this.saveLoansLocally(nodes)
        nodes.forEach(e =>{
          loansContainer.appendChild(e)
          ids.push(e.getAttribute('data-id'))
        })
        this.documentIdTarget.value = ids.join(',')
      }
      this.clear(true)
      document.getElementById('loan_list_container').classList.toggle('show')
    }
  }

  preSelectedItemsIds(){
    let ids = [...document.querySelectorAll('.loan-preselected-box > div')].map( e => {
      return parseInt(e.getAttribute('data-id'))
    })
    return ids
  }
}
