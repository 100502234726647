import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newFilter"]

  connect(){
    $('#filterNameContainer').on('click', 'i.fa', (e) => {
      this.deleteFilterName(e.currentTarget.dataset.filterName)
      let filterToDelete = e.currentTarget.parentElement.parentElement
      filterToDelete.parentElement.removeChild(filterToDelete)
    })
    
    this.populateFilterNames()
  }

  saveFilterName(e){
    const filterName = this.newFilterTarget.value
    if(filterName === ''){
      this.newFilterTarget.focus()
      return
    }

    const filtersNames = this.getFiltersNameState()
    const filterAlreadyCreated = filtersNames.filters.find((filter) => {
      return filter.name === filterName
    })
    if(filterAlreadyCreated){
      alert('Otro filtro con el mismo nombre ya existe')
      return
    }
    
    const newFilterName = {
      name: filterName,
      url: `${window.location.href}&fn=${filterName.replace(/\s/g, '')}`
    }

    filtersNames.filters.push(newFilterName)
    localStorage.setItem(this.getFilterNameKey(), JSON.stringify(filtersNames))
    this.showNewFilterName(newFilterName)
  }

  deleteFilterName(name){
    const filterNames = this.getFiltersNameState()
    filterNames.filters = filterNames.filters.filter((e)=> {
      e.name !== name
    })
    localStorage.setItem(this.getFilterNameKey(), JSON.stringify(filterNames))
  }
  
  showNewFilterName(filterName){
    const template = document.getElementById("filterNameTemplate").content.cloneNode(true)
    const filterUrl = template.querySelector('a.filter-url')
    filterUrl.href = filterName.url
    filterUrl.text = filterName.name
    template.querySelector('i.fa').dataset.filterName = filterName.name
    document.getElementById('filterNameContainer').appendChild(template);
  }

  populateFilterNames(){
    const filterNames = this.getFiltersNameState()
    const template = document.getElementById("filterNameTemplate")
    const filterNameContainer = document.getElementById('filterNameContainer')

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const filterNameParam = urlParams.get('fn')

    filterNames.filters.forEach((e) => {
      const filterNameTemplate = template.content.cloneNode(true)
      const filterUrl = filterNameTemplate.querySelector('a.filter-url')
      filterUrl.href = e.url
      filterUrl.text = e.name
      filterNameTemplate.querySelector('i.fa').dataset.filterName = e.name
      if(filterNameParam && window.location.href === e.url){
        filterUrl.parentElement.classList.add('badge-success')
      }
      filterNameContainer.appendChild(filterNameTemplate);
    })
  }

  getFiltersNameState(){
    let state = JSON.parse(localStorage.getItem(this.getFilterNameKey()))
    if(state === null){
      state = {
        filters: []
      }
    }
    return state
  }

  getFilterNameKey(){
    return 'ddc-filters-name'
  }
}